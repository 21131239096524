<template>
   <div class="page_section">
      <MobileTitle :title="banner.title"/>
      <Banner :content="banner"/>
      <div class="content_element account_information_element">
         <div class="container">
            <div class="row">
               <div class="col-12 col-xl-6 col-lg-6 col-md-12">
                  <h5 class="account_information_title">Informationen</h5>
                  <table class="account_information_table" v-if="user">
                     <tr>
                        <td>Name:</td>
                        <td>{{ user.name }} {{ user.surname }}</td>
                     </tr>
                     <tr>
                        <td>E-Mail:</td>
                        <td>{{ user.email }}</td>
                     </tr>
                     <tr>
                        <td>Passwort:</td>
                        <td>********</td>
                     </tr>
                     <tr>
                        <td><a href="#" @click.prevent="changePasswordVisible = !changePasswordVisible"
                               class="change_password_link">Passwort ändern</a></td>
                        <td>&nbsp;</td>
                     </tr>
                  </table>
                  <div class="application_status_change_password" v-if="changePasswordVisible">
                     <vue-form :state="formstate" @submit.prevent="changePassword"
                               id="applicationStatusChangePassword">
                        <validate tabindex="5">
                           <InputText
                              name="oldPassword"
                              placeholder="Passwort"
                              label="Altes Passwort"
                              inputId="oldPassword"
                              v-model="model.oldPassword"
                              type="password"
                              :required="true"
                              :disabled="false"
                           />
                           <field-messages name="oldPassword" class="vf-error_message">
                              <template slot="required" slot-scope="state">
                                 <span v-if="state.$dirty || state.$submitted">
                                    {{ 'Altes Passwort' }} is a required field
                                 </span>
                              </template>
                              <template slot="passwordStrength" slot-scope="state">
                                 <span v-if="state.$dirty || state.$submitted">{{ 'Altes Passwort' }} requires UpperCase, LowerCase, Number/SpecialChar and min 8 Chars</span>
                              </template>
                           </field-messages>
                        </validate>

                        <validate tabindex="5" :custom="{passwordStrength: passwordStrength}">
                           <InputText
                              name="newPassword"
                              placeholder="Passwort"
                              label="Neues Passwort"
                              inputId="newPassword"
                              v-model="model.newPassword"
                              type="password"
                              :required="true"
                              :disabled="false"
                           />
                           <field-messages name="newPassword" class="vf-error_message">
                              <template slot="required" slot-scope="state">
                                 <span
                                    v-if="state.$dirty || state.$submitted">{{ 'Neues Passwort' }} is a required field</span>
                              </template>
                              <template slot="passwordStrength" slot-scope="state">
                                 <span v-if="state.$dirty || state.$submitted">{{ 'Neues Passwort' }} requires UpperCase, LowerCase, Number/SpecialChar and min 8 Chars</span>
                              </template>
                           </field-messages>
                        </validate>

                        <validate tabindex="5" :custom="{matches: matches}">
                           <InputText
                              name="passwordConfirmation"
                              label="Passwort Bestätigung"
                              placeholder="Passwort"
                              inputId="registerPasswordConfirmation"
                              v-model="model.passwordConfirmation"
                              type="password"
                              :required="true"
                              :disabled="false"
                           />
                           <field-messages name="passwordConfirmation" class="vf-error_message">
                              <template slot="required" slot-scope="state">
                                 <span v-if="state.$dirty || state.$submitted">{{ 'Passwort Bestätigung' }} is a required field</span>
                              </template>
                              <template slot="matches" slot-scope="state">
                                 <span v-if="state.$dirty || state.$submitted">Passwords do not match</span>
                              </template>
                           </field-messages>
                        </validate>

                        <button class="btn btn-grey" :disabled="formSending">
                           <template v-if="formSending">Passwort ändern ...</template>
                           <template v-else>Passwort ändern</template>
                        </button>
                     </vue-form>
                  </div>
                  <div class="row">
                     <div class="col-6 text-left">
                        <button @click.prevent="$store.dispatch('authentication/logout')" class="btn btn-grey ">
                           <i class="ab-icon-logout"></i> Logout
                        </button>
                     </div>
                     <div class="col-6 text-right">
                        <button @click.prevent="deleteUser()" class="btn btn-grey ">Konto löschen</button>
                     </div>
                  </div>
               </div>
               <div
                  class="col-12 col-xl-5 offset-xl-1 col-lg-5 offset-lg-1 col-md-12 offset-md-0 account_saved_rides_table_info">
                  <template v-if="savedRoutes.saved.length > 0">
                     <h5 class="account_information_title">Meine gespeicherten Fahrten</h5>
                     <table class="account_saved_rides_table" v-if="savedRoutes.saved">
                        <template v-for="(item, index) in savedRoutes.saved">
                           <tr :key="'saved-' + index">
                              <td>{{ item.departure }}</td>
                              <td>
                                    <span class="icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="40.301" height="15.746"
                                             viewBox="0 0 40.301 15.746">
                                          <g id="Group_2" data-name="Group 2" transform="translate(-102.078 -106.906)">
                                            <g id="Group_1" data-name="Group 1" transform="translate(103 62.773)">
                                              <path id="Line_6_Copy_27" data-name="Line 6 Copy 27" d="M36.5,1,.084.782"
                                                    transform="translate(0 51)" fill="none" stroke="#4a4a4a"
                                                    stroke-linecap="square" stroke-miterlimit="10" stroke-width="2"/>
                                            </g>
                                            <path id="Fill_1_Copy_11" data-name="Fill 1 Copy 11"
                                                  d="M7.873,0,0,7.879v2.133L7.873,2.141l7.873,7.866V7.879Z"
                                                  transform="translate(142.379 106.906) rotate(90)" fill="#4a4a4a"/>
                                          </g>
                                        </svg>
                                    </span>
                              </td>
                              <td>{{ item.arrival }}</td>
                              <td>{{ item.date }}</td>
                              <td class="favorite_places_wrapper">
                                 <i class="ab-icon-x remove-icon" @click.prevent="removeSavedRoute(index)"></i>
                              </td>
                           </tr>
                        </template>
                     </table>
                  </template>

                  <div class="favorite_places_content">
                     <h5 class="account_information_title">Meine Lieblingsorte</h5>
                     <div class="favorite_places_wrapper">
                        <a href="#" class="favorite_places_item" v-for="(place, index) in savedPlaces"
                           :key="'fromsvp-' + index" @click.prevent="editPlacePopupVisible=true; editPlace = place">
                                        <span class="icon">
                                           <i class="ab-icon-home" v-if="place.type === 1"></i>
                           <i class="ab-icon-job" v-if="place.type === 2"></i>
                           <i class="ab-icon-train" v-if="place.type === 3"></i>
                           <i class="ab-icon-events" v-if="place.type === 4"></i>
                                        </span>
                           {{ place.name }}
                        </a>
                        <a href="#" class="favorite_places_item" v-if="savedPlaces.length < 4"
                           @click.prevent="newPlacePopupVisible = true">
                                        <span class="icon">
                                            <i class="ab-icon-plus"></i>
                                        </span>
                           weiteren Ort hinzufügen
                        </a>
                     </div>
                  </div>
               </div>
            </div>
            <div class="row">
               <div class="col-12 col-sm-12">
                  <div class="row">
                     <div class="col-sm-6 offset-sm-1 favorite_places_content">

                     </div>
                  </div>
               </div>
            </div>
            <!-- <hr> -->
         </div>
         <div class="br_line"></div>
      </div>
      <Popup :visible="addNewPlace" @popupClose="addNewPlace = false">
         <template v-slot:header>
            <h4>weiteren Ort hinzufügen</h4>
         </template>
         <div>
            <p class="font-weight-light mb25">Lorem ipsum dolor sit amet, consectetur adipisicing elit. At consequatur
               culpa ex iste laborum
               reprehenderit.</p>
            <input type="text" class="form-control" placeholder="Address">
         </div>
         <template v-slot:footer>
            <div class="text-right">
               <button class="btn btn-primary">Speichern</button>
            </div>
         </template>
      </Popup>
      <Footer/>


      <MyPlaces
         v-on:newPlacePopupClosed="newPlacePopupVisible = false"
         v-on:editPlacePopupClosed="editPlacePopupVisible = false"
         v-on:updated="updateSavedPlaces"
         :editPlace="editPlace"
         :newPlacePopupVisible.sync="newPlacePopupVisible"
         :editPlacePopupVisible.sync="editPlacePopupVisible"
      />
   </div>
</template>

<script>
   import {mapState} from 'vuex';
   import MobileTitle from "../_common/MobileTitle";
   import Footer from "../_common/Footer";
   import Banner from "../_content_elements/Banner";
   import {
      InputText
   } from '../../components/_form';
   import Popup from "../_common/Popup";
   import MyPlaces from "../_common/MyPlaces";

   export default {
      name: "PersonalArea",
      components: {
         MyPlaces,
         Popup,
         MobileTitle,
         Footer,
         Banner,
         InputText
      },
      data() {
         return {
            deleteAccountPopupVisible: false,
            formstate: {},
            model: [],
            formSending: false,
            addNewPlace: false,
            changePasswordVisible: false,
            banner: {
               title: 'Willkommen in Deinem persönlichen Bereich',
               image: '/abellio_banner.jpg',
               smallBanner: true
            },

            editPlace: null,
            editPlacePopupVisible: false,
            newPlacePopupVisible: false,
            savedPlaces: [],
         }
      },
      computed: {
         ...mapState([
            'authentication',
            'alert',
            'savedRoutes'
         ]),
         user() {
            if (this.authentication.loggedIn) {
               return this.authentication.user.user;
            }

            return null;
         }
      },
      methods: {
         matches: function (value) {
            if (!this.model.newPassword) {
               return true;
            }
            return value === this.model.newPassword;
         },
         passwordStrength(value) {
            return /(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/.test(value);
         },
         changePassword() {
            if (this.formstate.$invalid) {
               document.getElementsByClassName('vf-field-invalid')[0].focus();
               return;
            }
            this.formSending = true;
            this.$store.dispatch('authentication/changePassword', {
               old_password: this.model.oldPassword,
               new_password: this.model.newPassword,
               password_confirmation: this.model.passwordConfirmation
            });
         },
         completenessColor(val) {
            const percent = parseFloat(val);
            if (percent >= 100) {
               return 'color_green';
            } else if (percent >= 50) {
               return 'color_yellow';
            } else {
               return 'color_red';
            }
         },
         deleteUser() {
            this.$confirm(
               {
                  message: `Are you sure?`,
                  button: {
                     no: 'No',
                     yes: 'Yes'
                  },
                  callback: confirm => {
                     if (confirm) {
                        this.$store.dispatch('authentication/deleteUser');
                     }
                  }
               }
            )
         },
         updateSavedPlaces() {
            let myPlaces = JSON.parse(localStorage.getItem("myPlaces"));
            if (myPlaces !== null) {
               this.savedPlaces = myPlaces;
            }
         },
         removeSavedRoute(index) {
            let savedRoutes = [];

            this.savedRoutes.saved.forEach((value, key) => {
               if (key !== index) {
                  savedRoutes.push(value);
               }
            })

            this.$store.commit("savedRoutes/updateRoutesState", savedRoutes)
         }
      },
      created() {
         this.updateSavedPlaces()
      },
      watch: {
         'alert.type': function (val) {
            this.formSending = false;
            if (val == 'alert-success') {
               this.model = [];
               this.formstate._reset();
            }
         }
      }
   }
</script>

<style lang="scss" scoped>
   .remove-icon {
      color: red;
      font-size: 0.5rem;
      font-weight: bold;
      cursor: pointer;
   }
</style>
